import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { PhotoSwipe } from 'react-photoswipe'
import Image from './Image'
import _kebabCase from 'lodash/kebabCase'
import './Gallery.css'
import 'react-photoswipe/lib/photoswipe.css'

export const query = graphql`
  fragment Gallery on MarkdownRemark {
    frontmatter {
      gallery {
        alt
        image
        title
      }
    }
  }
`

export default class Gallery extends Component {
  state = {
    loaded: false,
    isOpen: false,
    sliderImages: [],
    index: 0
  }

  isOpen(isOpen, index) {
    if (typeof index === 'undefined') index = 0
    this.setState({ isOpen, index })
  }

  getImageInfo = (img, index) =>
    fetch(img.image + '-/json/')
      .then(res => res.json())
      .then(
        result => {
          const newImagesArr = [...this.state.sliderImages]
          newImagesArr[index] = {
            src: img.image,
            title: img.title,
            w: result.width,
            h: result.height
          }
          this.setState({
            sliderImages: newImagesArr
          })
          return true
        },
        error => {
          console.log(error)
          return false
        }
      )

  componentDidMount() {
    const { images } = this.props,
      maxCount = images.length
    let loopCount = 1

    for (let i in images) {
      if (this.getImageInfo(images[i], i)) {
        this.setState({ loaded: loopCount === maxCount })
        loopCount++
      }
    }
  }

  sh = (img, index) =>
    fetch(img.image + '-/json/')
      .then(res => res.json())
      .then(
        result => {
          const newImagesArr = [...this.state.sliderImages]
          newImagesArr[index] = {
            src: img.image,
            title: img.title,
            w: result.width,
            h: result.height
          }
          this.setState({
            sliderImages: newImagesArr
          })
          return true
        },
        error => {
          console.log(error)
          return false
        }
      )

  render() {
    const { images } = this.props
    return (
      <Fragment>
        {images &&
          images.length > 0 && (
            <div className="Gallery">
              {images.map((image, index) => (

                <figure
                  className="Gallery--Item"
                  key={_kebabCase(image.alt) + '-' + index}
                >
                  <div
                  onClick={() => this.isOpen(true, index)}>
                    <Image background
                      resolutions="small"
                      src={image.alt === 'B-roll video 2 (June 12, 2020)' || image.alt === 'B-roll video (Dec 22, 2019)' ? `https://ucarecdn.com/cf4f8177-5b84-4edf-9417-3be6b7562a41/` : image.image}
                      alt={image.alt}
                    />
                  </div>
                  <a href={`${image.image}/-/inline/no/`} download="">{[
                    (image.alt === 'B-roll video 2 (June 12, 2020)' ? 'B-roll video 2 (June 12, 2020)' : null),
                    (image.alt === 'B-roll video (Dec 22, 2019)' ? 'B-roll video 1 (Dec 22, 2019)' : null),
                    'Download'
                    ]}</a>
                </figure>
              ))}
            </div>
          )}
        {this.state.loaded &&
          this.state.sliderImages.length > 0 && (
            <PhotoSwipe
              isOpen={this.state.isOpen}
              items={this.state.sliderImages}
              options={{
                index: this.state.index,
                history: false,
                shareButtons: [
                  {id:'download', label:'Download image', url:'{{raw_image_url}}'+'/-/inline/no/', download:true}
              ]
              }}
              onClose={() => this.isOpen(false)}
            />
          )}
      </Fragment>
    )
  }
}

Gallery.propTypes = {
  images: PropTypes.array.isRequired
}
