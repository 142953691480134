import React from 'react'
import { graphql } from 'gatsby'
import Content from '../components/Content.js'
import PageHeader from '../components/PageHeader'
import Layout from '../components/Layout'
import Gallery from '../components/Gallery'
import Donate from '../components/Donate'
import BackgroundVideo from '../components/BackgroundVideo'


// Export Template for use in CMS preview
export const DefaultPageTemplate = ({
  title,
  body,
  gallery,
  section1,
  video
}) => (
  <main className="DefaultPage">
    <section className="section">
    {/* <div><BackgroundVideo>
        {video && <source src={video} type="video/mp4" />}
      </BackgroundVideo></div> */}
    <div className="container">
        <Content source={section1} />
      </div>
      <div className="container">
        <Gallery images={gallery} />
      </div>
    </section>
  </main>
)

const DefaultPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <DefaultPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)
export default DefaultPage

export const pageQuery = graphql`
  query DefaultPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Gallery
      ...Meta
      html
      frontmatter {
        title
        section1
        video
      }
    }
  }
`
